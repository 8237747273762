import axios from "axios";
const queryString = require('query-string');
import { rentalNewsCnf } from "@/constant/config";

export default {
    getDataView(requestData) {
        let queryParams = queryString.stringify(requestData);
        return axios({
            method: "GET",
            url: `${rentalNewsCnf.getDataView}?${queryParams}`,
            withCredentials: true,
        });
    },
    getById(id) {
        return axios({
            method: "GET",
            url: `${rentalNewsCnf.getById}/${id}`,
            withCredentials: true,
        });
    },
    insertOrUpdate(data) {
        return axios({
            method: "POST",
            url: `${rentalNewsCnf.insertOrUpdate}`,
            data: data,
            withCredentials: true,
        });
    },
    deleteById(id) {
        return axios({
            method: "GET",
            url: `${rentalNewsCnf.deleteById}/${id}`,
            withCredentials: true,
        });
    },
}